import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/Home.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/branch/:branchId',
    name: 'branch',
    component: () => import('../views/Branch.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/branch/:areaManager',
    name: 'areaManager',
    component: () => import('../views/Branch.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/status/:status/:carriedId',
    name: 'status',
    component: () => import('../views/Status.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/branch/:branchType',
    name: 'branchType',
    component: () => import('../views/Branch.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/track/:carrierId/:trackingId',
    name: 'track',
    component: () => import('../views/Track.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/bulk-track/:carrierId',
    name: 'bulk-track',
    component: () => import('../views/BulkTrack.vue'),
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes: routes
})

router.beforeResolve(async (to, from, next) => {
  let userLoggedIn = false

  try {
    await Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
    userLoggedIn = true
  } catch (e) {}

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (userLoggedIn && to.name === 'login') {
      next({ path: '/' })
    } else if (userLoggedIn) {
      next()
    } else {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    }
  }
  next()
})

export default router
