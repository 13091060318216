const awsConfig = {
  Analytics: {
    disabled: true
  },
  Auth: {
    mandatorySignIn: true,
    region: 'eu-west-2',
    userPoolId: process.env.VUE_APP_COGNITO_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID
  }
}

export default awsConfig
