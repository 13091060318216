<template>
  <div id="app" class="min-h-screen bg-gray-100">
    <Header/>
    <router-view/>
  </div>
</template>

<script>

import Header from '@/components/layout/Header'

export default {
  name: 'App',
  metaInfo: {
    title: 'Post Office PUDO Support Portal',
    titleTemplate: '%s | Post Office PUDO Support Portal'
  },
  components: {
    Header
  }
}
</script>
