import Vue from 'vue'

Vue.mixin({
  methods: {
    getIconUrl (icon) {
      try {
        return require(`@/assets/icons/carriers/${icon.toLowerCase()}.png`)
      } catch (error) {
        return require('@/assets/icons/carriers/default.png')
      }
    }
  }
})
