import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import VueLoading from 'vue-loading-overlay'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'vue-loading-overlay/dist/vue-loading.css'
import './assets/main.css'
import VueMeta from 'vue-meta'
import VueJsonToCsv from 'vue-json-to-csv'
import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import awsConfig from './awsConfig'
import '@/mixins/icons'

// vue meta
Vue.use(VueMeta)

// amplify
Vue.use(AmplifyPlugin, AmplifyModules)
Amplify.configure(awsConfig)

// axios
Vue.use(VueAxios, axios)

// moment
Vue.use(require('vue-moment'))

// loader
Vue.use(VueLoading)

// vuex
Vue.use(Vuex)

// csv export
Vue.use('vue-json-to-csv', VueJsonToCsv)

Vue.config.productionTip = false

const store = new Vuex.Store({
  state: {
    transferredData: null
  },
  mutations: {
    transferJsonData (state, payload) {
      state.transferredData = payload
    }
  },
  getters: {},
  actions: {}
})

new Vue({
  router,
  render: h => h(App),
  store: store
}).$mount('#app')
